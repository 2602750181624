// @tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --ant-red-5: #ff4d4f;
  --ant-green-5: #73d13d;
  --ant-green-5-opacity-50: rgba(115, 209, 61, 0.5);
  --ant-blue-5: #40a9ff;
  --blue-900: #11122C;
}

a {
  color: var(--blue-400);
  text-decoration: none;
}

.editor-content a {
  color: #40a9ff !important;
  text-decoration: underline;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
svg {
  flex-shrink: 0;
}

html {
  font-size: 16px;
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--blue-900);
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mantine-Table-root td, .mantine-Table-root th {
  background-color: white;
}
.mantine-Table-tr {
 a {
  color: #1A2433 !important;
 }
  color: #1A2433 !important;
  border-color: #DEE2E6 !important;
  a:hover{
    color: #228BE6 !important;
  }
}
.mantine-Table-th {
  border-top: 1px solid #DEE2E6 !important;
}
.mantine-Table-td {
  padding: 16px 12px !important;
}
.mrt-bottom-toolbar {
  justify-content: flex-start;
}
.mantine-Paper-root {
  border: none !important;
}

.mrt-show-hide-columns-menu {
  width: 600px !important;
  padding: 24px;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  .mantine-Divider-root {
    display: none;
  }
}

.breadcrumbs {
  font-size: 14px;

  a:hover{
    color: #75ADE3;
    text-decoration: none;
  }
  a:last-child {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: #1A2433;
  }
}